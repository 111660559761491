import "index.scss"

import "bootstrap/dist/js/bootstrap.bundle.js";
import "around/theme";

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"
import images from "../img/around/landing/influencer/*.{png,jpg}"
import map from "../img/around/map.jpg";

console.info("Bridgetown is loaded!")
console.log(images)

/**
 * @param {Event} event
 */
const handleSubmit = (event) => {
  event.preventDefault();

  /**
   * @var {HTMLFormElement} form
   */
  const form = event.target;
  const formData = new FormData(form);

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then((res) => {
      if (res.ok)
        form.classList.add(form.dataset.submitClass)
      else
        form.classList.add(form.dataset.errorClass);
    })
    .catch((error) => {
      form.classList.add(form.dataset.errorClass);
      console.error(error)
    });
};

document
  .querySelector("form")
  .addEventListener("submit", handleSubmit);
